<template>
  <div class="printBodyTotal">
    <kr-print-designer
      :temp-value="value"
      :widget-options="widgets"
      :lodop-license="licenseInfo"
      @save="handleSave"
      class="printBody"
    />
  </div>
</template>
<script>
// import KrPrintDesigner from 'kr-print-designer'
import { selectNoticeInfo, noticePrint } from "@/api/sale/notice";
export default {
  // components:{
  //   KrPrintDesigner
  // },
  data() {
    return {
      value: {
        title: "销货通知单",
        width: 750,
        height: 750,
        pageWidth: 750,
        pageHeight: 750,
        tempItems: [
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 770,
            height: 25,
            left: 0,
            top: 10,
            title: "公司名称",
            value: "绍兴上虞新越医疗器械有限公司产品销货通知单 ",
            defaultValue: "绍兴上虞新越医疗器械有限公司产品销货通知单",
            name: "",
            style: {
              FontSize: 15,
              Bold: true,
              Alignment: "center",
            },
            uuid: "7f7b0b45da",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 50,
            title: "No",
            value: "No：",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1411",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 95,
            top: 50,
            title: "No",
            value: "{ No } ",
            defaultValue: "",
            name: "noticeNo",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a142",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 275,
            top: 50,
            title: "订货日期",
            value: "订货日期： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a143",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 335,
            top: 50,
            title: "订货日期",
            value: "{订货日期} ",
            defaultValue: "orderDate",
            name: "orderDate",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a144",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 80,
            title: "客户名称",
            value: "客户名称： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a145",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 130,
            top: 80,
            title: "客户名称",
            value: "{客户名称} ",
            defaultValue: "customerName",
            name: "customerName",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a156",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 305,
            top: 80,
            title: "详细地址",
            value: "详细地址： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a146",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 400,
            height: 20,
            left: 365,
            top: 80,
            title: "详细地址",
            value: "{详细地址} ",
            defaultValue: "详细地址",
            name: "detailedAddress",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a147",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 110,
            title: "联系人",
            value: "联系人：",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1241",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 120,
            top: 110,
            title: "联系人",
            value: "{ 联系人 } ",
            defaultValue: "",
            name: "contacts",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1412",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 140,
            title: "付款方式",
            value: "付款方式：",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a141",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 135,
            top: 140,
            title: "付款方式",
            value: "{ 付款方式 } ",
            defaultValue: "",
            name: "paymentMethod2",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1432",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 275,
            top: 110,
            title: "联系电话",
            value: "联系电话： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1433",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 335,
            top: 110,
            title: "联系电话",
            value: "{联系电话} ",
            defaultValue: "联系电话",
            name: "contactNumber",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1424",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 475,
            top: 110,
            title: "新客户",
            value: "新客户： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1482",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 520,
            top: 110,
            title: "新客户",
            value: "{新客户} ",
            defaultValue: "新客户",
            name: "isNew2",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1249",
          },
          {
            type: "braid-table",
            title: "产品明细",
            isEdit: 0,
            dragable: true,
            resizable: true,
            width: 735,
            height: 500,
            left: 55,
            top: 170,
            title: "出库商品明细",
            value: "{ details } ",
            defaultValue: [],
            tabelHtml: "",
            columnsAttr: [
              {
                title: "产品编号",
                value: "{产品编号}",
                name: "productNo",
              },
              {
                title: "产品名称",
                value: "{产品名称}",
                name: "productName",
              },
              {
                title: "规格型号",
                value: "{规格型号}",
                name: "spec",
              },
              {
                title: "单位",
                value: "{单位}",
                name: "unit",
              },
              {
                title: "数量(批次)",
                value: "{数量(批次)}",
                name: "quantityBatch",
              },
            ],
            selectCol: ["amount", "batchNo", "expirationDate", "orderNo"],
            columns: [
              {
                title: "产品编号",
                value: "{产品编号}",
                name: "productNo",
              },
              {
                title: "产品名称",
                value: "{产品名称}",
                name: "productName",
              },
              {
                title: "规格型号",
                value: "{规格型号}",
                name: "spec",
              },
              {
                title: "单位",
                value: "{单位}",
                name: "unit",
              },
              {
                title: "数量(批次)",
                value: "{数量(批次)}",
                name: "quantityBatch",
              },
            ],
            name: "detailList",
            style: {
              zIndex: 0,
              Alignment: "center",
              FontSize: 9,
              FontColor: "#000000",
              BorderColor: "#000000",
              AutoHeight: true,
              BottomMargin: 0,
            },
            uuid: "8f1ee2cff3",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 385,
            title: "紧急情况",
            value: "紧急情况： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a2248",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 135,
            top: 385,
            title: "紧急情况",
            value: "{紧急情况} ",
            defaultValue: "紧急情况",
            name: "emergency2",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1293",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 405,
            title: "发货期限",
            value: "发货期限： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a2348",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 135,
            top: 405,
            title: "发货期限",
            value: "{发货期限} ",
            defaultValue: "发货期限",
            name: "deliveryDeadline",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1292",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 305,
            top: 405,
            title: "运输方式",
            value: "运输方式： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a249",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 365,
            top: 405,
            title: "运输方式",
            value: "{运输方式} ",
            defaultValue: "运输方式",
            name: "transportMode2",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a139",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 425,
            title: "备注",
            value: "备注： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a2448",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 500,
            height: 20,
            left: 135,
            top: 425,
            title: "备注",
            value: "{备注} ",
            defaultValue: "备注",
            name: "remarks",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a1239",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 445,
            title: "审核",
            value: "审核： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a248",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 115,
            top: 445,
            title: "审核",
            value: "{审核} ",
            defaultValue: "审核",
            name: "reviewerResult2",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a129",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 465,
            title: "批准",
            value: "批准： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a2439",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 115,
            top: 465,
            title: "批准",
            value: "{批准} ",
            defaultValue: "批准",
            name: "approverResult2",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a12239",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 485,
            title: "编制人",
            value: "编制人： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a2421",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 115,
            top: 485,
            title: "编制人",
            value: "{编制人} ",
            defaultValue: "编制人",
            name: "editor",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a12232",
          },
        ],
        default: true,
      },
      widgets: [
        {
          type: "braid-txt",
          isEdit: false,
          title: "自定义文本",
          value: "自定义文本",
          defaultValue: "自定义文本",
        },
        {
          type: "braid-txt",
          isEdit: true,
          title: "公司名称",
          value: "绍兴上虞新越医疗器械有限公司产品销货通知单",
          defaultValue: "绍兴上虞新越医疗器械有限公司产品销货通知单",
          name: "companyName",
          width: "450",
          top: "18",
          left: "160",
          style: {
            FontSize: 15,
            Bold: true,
            Alignment: "center",
          },
        },
        {
          type: "bar-code",
          title: "订单编号（条码）",
          value: "{orderNumber}",
          defaultValue: "1234567890",
          name: "orderNumber",
          lodopStyle: { QRCodeVersion: "1", QRCodeErrorLevel: "L" },
        },
        {
          type: "braid-html",
          title: "分页",
          value: "{第##页/共##页}",
          defaultValue:
            "<font ><span tdata='pageNO'>第##页</span>/<span tdata='pageCount'>共##页</span></font>",
        },
        {
          type: "braid-image",
          title: "logo",
          value:
            "http://192.168.7.229/syy/file/files/view/5f9fb42dabebb4049ffbd019",
        },
        {
          type: "braid-table",
          title: "产品明细",
          name: "details",
          value: "{details}",
          width: "635",
          top: "116",
          defaultValue: [
            {
              productName: "苹果ipone11pro",
              skuName: "iphone11pro256g",
              specModel: "165L",
              quantity: 3,
              price: "12.00",
            },
            {
              productName: "苹果ipone11pro",
              skuName: "iphone11pro124g",
              specModel: "165L",
              quantity: 3,
              price: "12.00",
            },
          ],
          columnsAttr: [
            {
              title: "产品编号",
              value: "{产品编号}",
              name: "productNo",
            },
            {
              title: "产品名称",
              value: "{产品名称}",
              name: "productName",
            },
            {
              title: "规格型号",
              value: "{规格型号}",
              name: "spec",
            },
            {
              title: "单位",
              value: "{单位}",
              name: "unit",
            },
            {
              title: "数量(批次)",
              value: "{数量(批次)}",
              name: "quantityBatch",
            },
          ],
        },
      ],
      licenseInfo: "",
      valueData: {},
    };
  },
  methods: {
    handleSave() {
      let id = this.$route.query.id;
      noticePrint(id)
        .then((res) => {
          console.log(res, "res");
          this.$lodop.preview(this.value, [this.valueData]);
        })
        .catch((rej) => {
          console.log(rej, "rej");
        });
      
    },
    selectNoticeInfoById(id) {
      selectNoticeInfo(id).then((res) => {
        res.isNew2 = res.isNew === 1 ? "是" : "否";
        res.paymentMethod2 = res.paymentMethod === 1 ? "款到发货" : "临时欠款";
        res.emergency2 =
          res.emergency == 1
            ? "KP"
            : res.emergency == 2
            ? "TK"
            : res.emergency == 3
            ? "CK"
            : "CW";
        res.transportMode2 =
          res.transportMode == 1
            ? "货运"
            : res.emergency == 2
            ? "快递"
            : res.transportModeOther;
        res.reviewerResult2 = `${
          res.reviewerResult == "1" ? "通过" : "不通过"
        }/${res.reviewerDate} ${res.reviewer}`;
        res.approverResult2 = `${
          res.approverResult == "1" ? "通过" : "不通过"
        }/${res.approverDate} ${res.approver}`;
        this.valueData = res;
      });
    },
  },

  mounted() {
    let id = this.$route.query.id;
    this.selectNoticeInfoById(id);
  },
};
</script>
<style lang="css">
.printBody .kr-designer-tool_bar button[type="button"]:last-child {
  display: none;
}
.printBody .kr-designer-tool_bar button[type="button"]:first-child {
  font-size: 35px;
}
</style>
<style lang="css" scoped>
@import url("./kr-print-designer2.css");
</style>
